import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import enhanceWithClickOutside from 'react-click-outside';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { FaUser } from 'react-icons/fa';
import Dropdown from 'react-dropdown';
import { NOMINATION } from '../constants/roles';
import { setRole } from '../actions/setRegion';
import { IoIosClose } from 'react-icons/io';
import { get } from 'lodash';
import { DeleteButton } from './Button';

const UserBox = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 260px;
  border: 1px solid #f1f1f1;
  padding: 8px 0;
  background-color: #fff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
  z-index: 100;

  li {
    padding: 0.5rem 0;

    & + li {
      border-top: 1px solid #e9ecef;
    }

    a,
    span {
      display: block;
      padding: 0.25rem 1.5rem;
      color: #212529;
      font-size: 13px;
      line-height: 1.5;
      text-decoration: none;
      cursor: pointer;
      white-space: nowrap;

      &:hover {
        color: #16181b;
        background-color: #f8f9fa;
      }
    }
  }
`;
const TopBox = styled.div`
  display: flex;
  padding: 0.25rem 1rem 0.5rem;
  border-bottom: 1px solid #e9ecef;

  .close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    @media (min-width: 768px) {
      display: none;
    }
  }
`;
const AvatarImg = styled.div`
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-radius: 5px;
  overflow: hidden;
  object-fit: cover;

  img {
    object-fit: cover;
    height: 100%;
    max-width: 100%;
    width: 100%;
  }

  .pg-viewer-wrapper {
    overflow: visible;
  }

  .photo-viewer-container,
  .photo-viewer-container {
    width: 100% !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
`;
const InfoBox = styled.div`
  max-width: calc(100% - 45px);
  padding: 0 10px;
  flex: 1;

  h4 {
    margin-bottom: 5px;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 3px;
  }

  .email {
    margin-bottom: 5px;
    color: #6c757d;
    font-size: 12px;
    word-break: break-word;
  }

  .userRole {
    font-size: 14px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Dropdown-root {
    width: calc(100% - 40px);
    font-size: 12px;
    border: none;

    @media (min-width: 768px) {
      width: 100%;
      font-size: 14px;
    }
  }

  .Dropdown-placeholder,
  .Dropdown-option {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Dropdown-control {
    padding: 2px 3px;
    color: #16181b;
    cursor: pointer;
    border: none;
  }

  .Dropdown-arrow {
    border-color: #16181b transparent transparent;
    top: 10px;
    right: 4px;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #16181b;
  }

  .Dropdown-menu {
    background-color: #f2f9fc;
    border: 1px solid #ffffff26;
  }

  .Dropdown-placeholder {
    padding: 2px 18px 2px 0;
  }

  .Dropdown-option {
    &.is-selected {
      background-color: rgba(75, 152, 255, 0.31);
      color: #fff;
    }

    &:hover {
      background-color: rgba(74, 154, 255, 0.8);
      color: #fff;
    }
  }
`;

const RemoveButton = styled(DeleteButton)`
  width: calc(100% - 3rem);
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

const token = 'AuthToken';

class UserData extends Component {
  handleClickOutside() {
    this.props.togglePopUp(false);
  }

  userMenuList = () => {
    const { logOutUser } = this.props;

    return (
      <ul>
        <li onClick={() => this.props.togglePopUp(false)}>
          <Link to="/user-profile">My Profile</Link>
        </li>
        <li onClick={() => this.props.togglePopUp(false)}>
          <Link to="/settings">Settings</Link>
        </li>
        <li>
          <span onClick={() => logOutUser()}>Logout</span>
        </li>
      </ul>
    );
  };

  nomineeMenuList = () => {
    const { logOutUser } = this.props;

    return (
      <ul>
        <li onClick={() => this.props.togglePopUp(false)}>
          <Link to="/user-profile">My Profile</Link>
        </li>
        <li onClick={() => this.props.togglePopUp(false)}>
          <Link to="/settings">Settings</Link>
        </li>
        <li>
          <span onClick={() => logOutUser()}>Logout</span>
        </li>
      </ul>
    );
  };

  fbUserMenuList = () => {
    const { logOutUser, removeVote } = this.props;

    return (
      <ul>
        <li onClick={() => this.props.togglePopUp(false)}>
          <RemoveButton onClick={removeVote} type="button">Delete my profile</RemoveButton>
        </li>

        <li>
          <span onClick={() => logOutUser()}>Logout</span>
        </li>
      </ul>
    );
  }

  render() {
    const { isOpened, user, setRole, fbLogin, fbPublicVoterUser } = this.props;
    const isNominee = user.role === NOMINATION;
    const prefixLink = process.env.REACT_APP_UPLOADED_FILES_LINK;

    let menu = user.role !== NOMINATION ? this.userMenuList() : this.nomineeMenuList();
    if (fbLogin) {
      menu = this.fbUserMenuList();
    }

    return (
      <div>
        {isOpened && (
          <UserBox>
            <TopBox>
              {isNominee ? (
                <AvatarImg>
                  {get(user, 'uploadedFiles.profilePicture.title', false) ? (
                    <img
                      src={`${prefixLink}?file=${user.uploadedFiles.profilePicture.title}&token=${token}`}
                      alt="Avatar"
                    />
                  ) : (
                    <FaUser size={36} />
                  )}
                </AvatarImg>
              ) : (
                <AvatarImg>
                  {user.image ? <img src={user.image} alt="Avatar" /> : <FaUser size={36} />}
                </AvatarImg>
              )}

              <InfoBox>
                <h4>{user.name}</h4>
                {user && user.role !== NOMINATION && !fbLogin ? (
                  <div>
                    {user.availableRoles ? (
                      <Dropdown
                        options={user.availableRoles.map((item) => {
                          return { value: item.role, label: item.roleLabel };
                        })}
                        onChange={(data) => {
                          setRole(data.value, data.label);
                        }}
                        value={user.role}
                        placeholder="Select role"
                      />
                    ) : (
                      "User don't have available roles"
                    )}
                  </div>
                ) : (
                  <div className="userRole">{user.role}</div>
                )}

                {(fbLogin) ? `${fbPublicVoterUser.name}, public voter` : ''}
              </InfoBox>
              <div className="close" onClick={() => this.props.togglePopUp(false)}>
                <IoIosClose size="2.5em" />
              </div>
            </TopBox>
            {menu}
          </UserBox>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRole
    },
    dispatch
  );

export default compose(connect(null, mapDispatchToProps), enhanceWithClickOutside)(UserData);
